import React from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import styles from './NavigationDetails.module.css';
import { format } from 'date-fns';

class NavigationDetails extends React.PureComponent {

    toggleModal = (key, value) => {
        if (this.props.enableEditFormDetails) {
            this.props.$toggleModal(key, value);
        }
    }

    render() {

        return (
            <>
                <Grid fluid style={{ boxShadow: 'none' }} className={styles.navbar} >

                    <Row>
                        {/* <Col style={{ textAlign: 'center' }} md={3} lgHidden>
                                <Avatar size='lg' style={{ background: 'white' }} src={this.props.logo}></Avatar>
                            </Col> */}

                        <Col onClick={() => this.toggleModal('editDetails', true)} className={styles.dataBlocks} md={5} sm={24}>
                            <div style={{ marginLeft: '-40px' }}>
                                <Icon icon='sequence-up' />
                            </div>
                            <div style={{ marginLeft: '15px' }}>
                                <strong className={styles.pill} >From</strong>
                                <br />
                                <div className={styles.data}>{this.props.checkOutLocation}</div>
                            </div>
                        </Col>

                        <Col onClick={() => this.toggleModal('editDetails', true)} className={styles.dataBlocks} md={6} sm={24} >
                            <div style={{ marginLeft: '-40px' }}>
                                <Icon icon='sequence-down' />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <strong className={styles.pill}>To</strong>
                                <br />
                                <div className={styles.data}>{this.props.checkInLocation}</div>
                            </div>
                        </Col>

                        <Col onClick={() => this.toggleModal('editDetails', true)} className={styles.dataBlocks} md={6} sm={24} >
                            <div style={{ marginLeft: '-40px' }}>
                                <Icon style={{ transform: 'scale(0.8)' }} size='2x' icon='calendar-minus-o' />
                            </div>
                            <div style={{ marginLeft: '15px' }}>
                                <strong className={styles.pill}>Start Date</strong>
                                <br />
                                <div className={styles.data}>{format(this.props.form.pickUpDate, this.props.dateFormat)}</div>
                            </div>
                        </Col>

                        <Col onClick={() => this.toggleModal('editDetails', true)} className={styles.dataBlocks} md={6} sm={24} >
                            <div style={{ marginLeft: '-40px' }}>
                                <Icon style={{ transform: 'scale(0.8)' }} size='2x' icon='calendar-minus-o' />
                            </div>
                            <div style={{ marginLeft: '15px' }}>
                                <strong className={styles.pill}>End Date</strong>
                                <br />
                                <div className={styles.data}>{format(this.props.form.returnDate, this.props.dateFormat)}</div>
                            </div>
                        </Col>

                    </Row>


                </Grid>
                {/* <div className={styles.banner}>
                </div>
                <div className={styles.card} style={{ fontSize: '19px', padding: '20px ', margin: '-40px 20% 0 20%' }}>
                    <Icon size='2x' style={{ color: '#5540CC', marginRight: '10px' }} icon='clock-o' /> <strong>It's free!</strong> <span style={{ fontSize: '15px' }}> You can change booking details up to 48h before your trip.</span>
                </div> */}
            </>
        )
    }
}

export default NavigationDetails;
