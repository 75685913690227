
const ActionType = {
    CHANGE_FORM: "CHANGE_FORM",
    CHANGE_UISETTINGS: "CHANGE_UISETTINGS",
    CHANGE_STEPPER_POSITION: "CHANGE_STEPPER_POSITION",

    IMPORT_HOME_COMPONENT: "IMPORT_HOME_COMPONENT",
    IMPORT_FLOW_COMPONENT: "IMPORT_FLOW_COMPONENT",
    IMPORT_STYLES: "IMPORT_STYLES",
    IMPORT_BOOKING_SUCCESS_COMPONENT: "IMPORT_BOOKING_SUCCESS_COMPONENT",
    IMPORT_NAVBAR: "IMPORT_NAVBAR",
    IMPORT_FOOTER: "IMPORT_FOOTER",

    REFRESH_TOKEN: "REFRESH_TOKEN",
    FETCH_CLIENT: "FETCH_CLIENT",
    SET_VEHICLES: "SET_VEHICLES",
    SET_VEHICLE_ITEMS: "SET_VEHICLE_ITEMS",
    CLEAR_VEHICLE_ITEMS: "CLEAR_VEHICLE_ITEMS",

    SET_PRICE: "SET_PRICE",
    ADD_PRICE: "ADD_PRICE",
    CLEAR_PRICE: "CLEAR_PRICE",

    SET_MISC: "SET_MISC",
    SET_INVENTORY: "SET_INVENTORY",
    ADD_MISC: "ADD_MISC",
    ADD_INVENTORY: "ADD_INVENTORY",
    UPDATE_MISC: "UPDATE_MISC",
    UPDATE_INVENTORY: "UPDATE_INVENTORY",
    REMOVE_MISC: "REMOVE_MISC",
    REMOVE_INVENTORY: "REMOVE_INVENTORY",
    SET_STORE_HOURS: "SET_STORE_HOURS",
    SET_TAXES: "SET_TAXES",

    SET_SUMMERY: "SET_SUMMERY",

    SET_TERMS: "SET_TERMS",
    SET_DOCS: "SET_DOCS",

    TOGGLE_MODAL: "TOGGLE_MODAL",  
    SET_PROMO_CODE : "SET_PROMO_CODE",
    SET_PROMOTION_DISCOUNT : 'SET_PROMOTION_DISCOUNT',
    
    // ************** Documentation *****************

    SET_STEPPER: "SET_STEPPER",
    CLEAR_VEHICLE_TYPES: "CLEAR_VEHICLE_TYPES"
}

export default ActionType
