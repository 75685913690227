import ActionType from "./ActionsType";
import { mapStoreHoursAsDefault } from "../../tools/utils";
import { changeUISettings } from "./UIActions";
import {
  fetchMiscCharges,
  fetchInventoryCharges,
  fetchTaxes,
  fetchStoreHours,
  getSummery,
} from "./ApiActions";

const setDefaultCheckTimes = () => {
  return (dispatch) => {
    const defaultHours = mapStoreHoursAsDefault();
    dispatch(changeForm("pickUpDate", defaultHours.defaultCheckoutDate));
    dispatch(changeForm("returnDate", defaultHours.defaultCheckinDate));
    dispatch(changeForm("pickUpTime", defaultHours.defaultCheckoutTime));
    dispatch(changeForm("returnTime", defaultHours.defaultCheckinTime));
  };
};

const changeForm = (key, value) => {
  if (["pickUpDate", "pickUpTime", "returnDate", "returnTime"].includes(key)) {
    value = new Date(value);
  }

  return (dispatch) => {
    if (key === "selectedVehicle") {
      dispatch({ type: ActionType.CLEAR_VEHICLE_ITEMS });
      dispatch(fetchMiscCharges(value.vehicleTypeId));
    } else if (key === "location") {
      dispatch(fetchStoreHours(value));
    }
   
    dispatch({ type: ActionType.CHANGE_FORM, data: { key, value } });
     

    if (key === "dateOfBirth") {
      dispatch(fetchTaxes());
    }
  };
};

const setPrice = (price = 0, position) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_PRICE,
      data: { price: parseFloat(price), position },
    });
  };
};

const clearPrice = () => {
  return (dispatch) => {
    dispatch({ type: ActionType.CLEAR_PRICE, data: {} });
  };
};

const addPrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.ADD_PRICE,
      data: { price: parseFloat(price) },
    });
  };
};

const updateMisc = (misc, quantity, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.UPDATE_MISC,
      data: { misc, quantity, startDate, endDate },
    });
    dispatch(addPrice(misc));
    dispatch(getSummery());
  };
};

const updateInventory = (inv, quantity, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.UPDATE_INVENTORY,
      data: { inv, quantity, startDate, endDate },
    });
    dispatch(addPrice(inv.Total));
    dispatch(getSummery());
  };
};

const addMisc = (misc) => {
  return (dispatch) => {
    if (misc.description === "AdditionalDriver") {
      dispatch(changeUISettings("AdditionalDriver", true));
    }

    dispatch({ type: ActionType.ADD_MISC, data: { misc } });
    dispatch(addPrice(misc.total));
    dispatch(getSummery());
  };
};

const addInventory = (inventory) => {
  return (dispatch) => {
    if (inventory.IsQuantity && !inventory.Quantity) {
      inventory.Quantity = 1;
    }

    dispatch({ type: ActionType.ADD_INVENTORY, data: { inventory } });
    dispatch(addPrice(inventory.Total));
    dispatch(getSummery());
  };
};

const removeMisc = (misc) => {
  return (dispatch) => {
    if (!misc.isOptional) {
      return;
    }
    if (misc.description === "AdditionalDriver") {
      dispatch(changeUISettings("AdditionalDriver", false));
    }

    dispatch({ type: ActionType.REMOVE_MISC, data: { miscId: misc.id } });
    dispatch(addPrice(-1 * misc.total));
    dispatch(getSummery());
  };
};

const removeInventory = (inventory) => {
  return (dispatch) => {
    if (!inventory.IsOptional) {
      return;
    }

    dispatch({
      type: ActionType.REMOVE_INVENTORY,
      data: { inventoryId: inventory.Id },
    });
    dispatch(addPrice(-1 * inventory.Total));
    dispatch(getSummery());
  };
};

const clearvehicleTypes = () => {
  return (dispatch) => {
    dispatch({ type: ActionType.CLEAR_VEHICLE_TYPES, data: {} });
  };
};



 const setPromoCode= (promoCode)=> {
  return (dispatch)=> dispatch( { type: ActionType.SET_PROMO_CODE, payload: promoCode });
}

export {
  changeForm,
  setPrice,
  addPrice,
  addMisc,
  addInventory,
  updateMisc,
  updateInventory,
  removeMisc,
  removeInventory,
  setDefaultCheckTimes,
  clearPrice,
  clearvehicleTypes,
  setPromoCode
};
