import axios from "axios";
import store from "../redux/createStore";

const mainAxios = axios.create({
  baseURL: process.env.REACT_APP_RESERVATION_API_BASE_URL,
});

mainAxios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
  return config;
});

export default mainAxios;
