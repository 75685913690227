import ActionType from './ActionsType';
import mainAxios from '../../Axios/Axios'
import { changeForm } from './formActions';

const refreshToken = clientId => {
    return async dispatch => {
        try {
            const tokenReq = await mainAxios.get(`${process.env.REACT_APP_RESERVATION_API_BASE_URL}reservation/token/`);
            const token = tokenReq.data.token;

            dispatch({ type: ActionType.REFRESH_TOKEN, data: { token } });
           
        } catch (error) {
            console.log(error);
            alert('Error Occured While getting token.');
           
        }
    }
}

const decodeUrlData = (encrypted, clientid) => {
    return async dispatch => {
        try {
            
            const data = JSON.parse(atob(encrypted));

            
    
            for (let i in data) {
                dispatch(changeForm(i, data[i]));
            }

            if(data.customerId){

                const customer = await mainAxios.get(`api/v3/customers/${data.customerId}?clientId=${clientid}`);

                dispatch(changeForm('firstName', customer.data.firstName));
                dispatch(changeForm('lastName', customer.data.lastName));
                dispatch(changeForm('email', customer.data.email));
                dispatch(changeForm('phoneNumber', customer.data.hPhone));
                dispatch(changeForm('dateOfBirth', customer.data.dateOfbirth ? new Date(customer.data.dateOfbirth) : ""));
                dispatch(changeForm('driverLicNumber', customer.data.licenseNumber));
                dispatch(changeForm('driverLicExp', customer.data.licenseExpiryDate ? new Date(customer.data.licenseExpiryDate) : ""));
                dispatch(changeForm('driverLicIssueDate', customer.data.licenseIssueDate ? new Date(customer.data.licenseIssueDate) : ""));
                dispatch(changeForm('address', customer.data.address1));
                dispatch(changeForm('city', customer.data.city));
                dispatch(changeForm('zipCode', customer.data.zipCode));
                dispatch(changeForm('state', customer.data.stateId));
                dispatch(changeForm('country', customer.data.countryId));


            }

        } catch (err) {
            console.log(err);
            alert('Error Occured while decoding the data.');
            
        }
    }
}



export { refreshToken, decodeUrlData };
