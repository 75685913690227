import store from "../redux/createStore";
import {
  getTime,
  startOfTomorrow,
  isBefore,
  addDays,
  addHours,
  addMinutes,
  format,
} from "date-fns";
import Payment from "payment";

const jumpToSteps = (
  clientid = new URLSearchParams(window.location.search).get("clientid"),
  lang = new URLSearchParams(window.location.search).get("lang"),
  vehicleTypeId = new URLSearchParams(window.location.search).get("type")
) => {
  let { form } = store.getState();

  let urlParams = new URLSearchParams(window.location.search);
  const customerId = urlParams.get("customerId");

  if (customerId) {
    form = { ...form, customerId };
  }

  const crypt = btoa(
    JSON.stringify({
      pickUpDate: form.pickUpDate,
      pickUpTime: form.pickUpTime,
      returnDate: form.returnDate,
      returnTime: form.returnTime,
      location: form.location,
      promoCode: form.promoCode,
      CorpDiscoundNumber: form.CorpDiscoundNumber,
      differentDropOff: form.differentDropOff,
      differentDropOffLocation: form.differentDropOffLocation,
    })
  );

  if (lang) {
    window.top.location = `${window.location.origin}/flow/?q=${crypt}&clientid=${clientid}&type=${vehicleTypeId}&lang=${lang}`;
  } else {
    window.top.location = `${window.location.origin}/flow/?q=${crypt}&type=${vehicleTypeId}&clientid=${clientid}`;
  }
};

const mapStoreHoursAsDisable = (arr, disableTill) => {
  return (date) => {
    if (!!disableTill)
      return arr.includes(date.getDay()) || isBefore(date, disableTill);

    return arr.includes(date.getDay());
  };
};

const mapStoreHoursAsDefault = (
  checkInDate,
  checkOutDate,
  checkInTime,
  checkOutTime
) => {
  try {
    const state = store.getState();

    checkInTime = checkInTime || state.UISettings.defaultCheckinTime;

    if (
      state.UISettings.earliestPickup > 0 &&
      state.UISettings.hideOrDisableTimePicker === ""
    ) {
      let t = addMinutes(new Date(), state.UISettings.earliestPickup);

      checkOutTime = checkOutTime || format(t, "hh:mm aa");
    } else {
      checkOutTime = checkOutTime || state.UISettings.defaultCheckoutTime;
    }

    checkOutDate = checkOutDate || new Date();
    checkInDate =
      checkInDate ||
      addDays(
        checkOutDate,
        state.UISettings.minimumBookingDays
          ? parseInt(state.UISettings.minimumBookingDays)
          : 1
      );

    let checkInHour = parseInt(checkInTime.substring(0, 2));
    let checkInMinutes = parseInt(checkInTime.substring(3, 5));
    let checkOutHour = parseInt(checkOutTime.substring(0, 2));
    let checkOutMinutes = parseInt(checkOutTime.substring(3, 5));

    if (checkInTime.slice(-2) === "PM") {
      checkInHour += 12;
    }
    if (checkOutTime.slice(-2) === "PM") {
      checkOutHour += 12;
    }

    checkInTime = new Date();
    checkInTime.setHours(checkInHour);
    checkInTime.setMinutes(checkInMinutes);

    checkOutTime = new Date();
    checkOutTime.setHours(checkOutHour);
    checkOutTime.setMinutes(checkOutMinutes);

    // Now calculate the default Date

    if (getTime(new Date()) > getTime(checkOutTime)) {
      checkOutDate = startOfTomorrow();
      checkInDate.setDate(checkInDate.getDate() + 1);
    }

    return {
      defaultCheckinDate: checkInDate,
      defaultCheckoutDate: checkOutDate,
      defaultCheckinTime: checkInTime,
      defaultCheckoutTime: checkOutTime,
    };
  } catch (err) {
    console.log(err);
    return;
  }
};

const mapToInputPicker = (obj = [], labelName, valueName, key) => {
  try {
    const newData = obj.map((item) => {
      return {
        label: item[labelName],
        value: item[valueName],
        key: item[key],
      };
    });

    return newData;
  } catch (err) {
    console.log(err);
    alert("Error Occured while maping the countrties.");
  }
};

export function groupObject(arrObj, key, group) {
  let misc = { others: [], othersWithOptions: [] };

  arrObj.forEach((obj) => {
    if (group.includes(obj[key])) {
      if (misc[obj[key]]) {
        misc[obj.miscChargeType].push(obj);
      } else {
        misc[obj[key]] = [obj];
      }
    } else {
      if (!obj.options || obj.options.length === 0) {
        misc.others.push(obj);
      } else {
        misc.othersWithOptions.push(obj);
      }
    }
  });

  // To take shift others property at the end of the misc object
  const others = [...misc.others];

  delete misc.others;

  misc.others = others;

  return misc;
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export {
  jumpToSteps,
  mapStoreHoursAsDisable,
  formatFormData,
  mapStoreHoursAsDefault,
  mapToInputPicker,
};
