import en from "./en";
import fr from "./fr";
import de from "./de";
import es from "./es";
import jp from "./jp";
import zh from "./zh";
import en_camper from "./en_camper";
import en_alaska from "./en_alaska";

export default {
    en_camper,
    en_alaska,
    en,
    fr,
    de,
    es,
    jp,
    zh
};