import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Loading from "./components/Static/Loading/Loading";
import PrivateRoute from "./tools/PrivateRoute";

import { connect } from "react-redux";
import { refreshToken, decodeUrlData } from "./redux/actions/AuthActions";
import { fetchClient } from "./redux/actions/ApiActions";
import { setDefaultCheckTimes } from "./redux/actions/formActions";
import i18n from "i18next";

(async function () {
  await Promise.all([
    import("./App.css"),
    import("rsuite/dist/styles/rsuite-default.css"),
  ]);
})();

const home = React.lazy(() => import("./containers/home/Home"));
const Flow = React.lazy(() => import("./containers/Flow/Flow"));
const bookingSuccess = React.lazy(() =>
  import("./containers/RegisterSuccess/RegisterSuccess")
);
const Documentation = React.lazy(() => import("./Documentation/Main"));

class App extends React.Component {
  urlParams = new URLSearchParams(window.location.search);

  componentDidMount() {
    const lang = this.urlParams.get("lang");

    if (lang) {
      i18n.changeLanguage(lang);
    }

    const clientId = this.urlParams.get("clientid");
    this.props.$refreshToken(clientId);
  }

  componentDidUpdate(prevProps) {
    const clientId = this.urlParams.get("clientid");
    const myParam = this.urlParams.get("q");

    if (prevProps.token !== this.props.token) {
      this.props.$fetchClient(clientId).then((_) => {
        if (!!myParam) {
          this.props.$decodeUrlData(myParam, clientId);
        } else {
          this.props.$setDefaultCheckTimes();
        }
      });
    }
  }

  render() {
    return (
      <React.Suspense fallback={<Loading />}>
        <Router>
          <Switch>
            <PrivateRoute
              path="/"
              exact
              auth={!!this.urlParams.get("clientid")}
              component={home}
            />
            <PrivateRoute
              path="/flow"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/booking/success"
              auth={true}
              exact
              to="/"
              component={bookingSuccess}
            />

            <Route path="/docs" component={Documentation} />
          </Switch>
        </Router>
      </React.Suspense>
    );
  }
}

const mapPropsToState = (store) => {
  return {
    reservationNumber: store.form.reservationNumber,
    token: store.auth.token,
  };
};

const mapPropsByDispatch = (dispatch) => {
  return {
    $refreshToken: (clientId) => dispatch(refreshToken(clientId)),
    $decodeUrlData: (crypt, clientId) =>
      dispatch(decodeUrlData(crypt, clientId)),
    $fetchClient: (clientId) => dispatch(fetchClient(clientId)),
    $setDefaultCheckTimes: () => dispatch(setDefaultCheckTimes()),
  };
};

export default connect(mapPropsToState, mapPropsByDispatch)(App);
