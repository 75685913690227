const data = {
  UISettings: {
    modals: {
      homeScreen: false,
    },
    bannerColor: "#1f9c60",
    buttonColor: "#205273",
    secondaryColor: "#008080",
    primaryColor: "#303030",
    currency: "$",
    language: "en",
    defaultCountryIdx: 1,
    defaultStateIdx: 1,
    flow: "513/SelectCar-Default/VehicleView-Default/AddOns-513/Insurance-Default/GetClinetInfo-Default/GetPaymentInfoAndRegister",
    homeView: "513/NoLocationForm",
    theme: "default",
    bookingSuccessView: "Default/BookingSuccess",
    footer: "513",
    isAirLineInfoAvailable: false,
    isReservation: false,
    isVehicleCount: false,
    isAgeOnHome: "True",
    billingAddressInfo: "True",
    isDriversInfoOptional: "False",
    additionalDriver: true,
    navPrice: false,
    parent: "https://www.nativecampervans.com/",
    maxCheckinTime: "09:30 AM",
    timeSelection: "True",
    dateFormat: "MM/dd/yyyy",
    defaultCheckoutTime: "05:00 PM",
    defaultCheckinTime: "09:00 AM",
    driverLicense: "True",
    paymentInfo: "True",
    isPaymentInfoMandatory: "True",
    timeGap: 30,
    showClock: "Y",
    navBar: "513/NavigationBar",
    logo: "https://reservation.navotar.com/Content/img/xpressashwhite.png",
    config: {},
    enableEditFormDetails: false,
    clientId: "513",
    defaultCountryIndex: "2",
    defaultStateIndex: "13",
    currentActiveFlow: 0,
    showStepper: true,
    showDOB: "True",
    showPromoCode: true,
    vehicleTypes: [
      {
        id: 33630,
        value: "Biggie Campervan",
      },
      {
        id: 33629,
        value: "Smalls Campervan",
      },
      {
        id: 33631,
        value: "Squad Campervan",
      },
    ],
  },
  locations: [
    {
      locationId: 48164,
      stateName: "TEXAS",
      countryName: "USA",
      locationName: "Denver",
      address1: "23 main steet",
      address2: null,
      city: "Denver",
    },
    {
      locationId: 48165,
      stateName: "NEVADA",
      countryName: "USA",
      locationName: "Las Vegas",
      address1: "45 Main street",
      address2: null,
      city: "Las Vegas",
    },
    {
      locationId: 48166,
      stateName: "ARKANSAS",
      countryName: "USA",
      locationName: "Salt Lake City",
      address1: "Salt Lake City",
      address2: null,
      city: "Salt Lake City",
    },
  ],
  storeHours: [
    {
      day: 1,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: true,
    },
    {
      day: 2,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: false,
    },
    {
      day: 3,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: false,
    },
    {
      day: 4,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: false,
    },
    {
      day: 5,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: false,
    },
    {
      day: 6,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: false,
    },
    {
      day: 7,
      startTime: "9:00 AM",
      endTime: "5:00 PM",
      isHoliday: true,
    },
  ],
  price: [0, 0, 587, 29.95, 189, 0],
  form: {
    location: 48164,
    differentDropOff: true,
    pickUpDate: new Date("2020-12-02T05:00:00.000Z"),
    returnDate: new Date("2020-12-03T05:00:00.000Z"),
    pickUpTime: new Date("2020-12-01T14:00:51.202Z"),
    returnTime: new Date("2020-12-01T22:00:51.202Z"),
    age: "25+",
    differentDropOffLocation: 48165,
    selectedVehicle: {
      VehicleTypeId: 33630,
      VehicleTypeName: "Biggie Campervan",
      FullValue: 0,
      Sample: "sample",
      Seats: "3",
      Doors: "4",
      Baggages: 4,
      Transmission: "Auto",
      Deposit: 2000,
      Path: "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Biggie Campervan_33630.jpg",
      HtmlContent: "",
      Price: "0",
      SIPP: null,
      Images: [
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Biggie Campervan_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-1_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-2_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-3_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-4_33630.jpg",
      ],
      Total: 3,
      TotalBooked: 2,
    },
    selectedMisc: [
      {
        Id: 23169,
        Name: "Colorado Road Safety Fee",
        Total: 2,
        Description: "Colorado Road Safety Fee",
        CalculationType: "Perday",
        IsQuantity: false,
        IsOptional: false,
        IsTaxable: true,
        MiscChargeType: "Fuel",
        Options: [],
      },
      {
        Id: 23170,
        Name: "Preparation Fee",
        Total: 60,
        Description: "Preparation Fee",
        CalculationType: "Fixed",
        IsQuantity: false,
        IsOptional: false,
        IsTaxable: true,
        MiscChargeType: "Fuel",
        Options: [],
      },
      {
        Id: 23171,
        Name: "Ultimate Winter Package",
        Total: 375,
        Description: "Ultimate Winter Package",
        CalculationType: "Fixed",
        IsQuantity: false,
        IsOptional: false,
        IsTaxable: true,
        MiscChargeType: "Fuel",
        Options: [],
      },
      {
        Id: 23159,
        Name: "Inflatable Stand Up Paddleboard ",
        Total: 150,
        Description: "*Capped at $150",
        CalculationType: "Fixed",
        IsQuantity: false,
        IsOptional: true,
        IsTaxable: true,
        MiscChargeType: "Fuel",
        Options: [],
      },
      {
        Id: 1,
        Name: "LDW",
        Total: 29.95,
        Description: "Loss damage waiver",
        CalculationType: "Fixed",
        IsQuantity: false,
        IsOptional: true,
        IsTaxable: true,
        MiscChargeType: "Insurance",
        Options: [
          {
            miscChargeOptionId: 10526,
            name: "Assume Liability @ $0.00 Per Day",
            option: 100,
            value: 0,
            miscChargeName: "LDW",
            vehicleTypeName: "All",
            locationName: "All",
          },
          {
            miscChargeOptionId: 10527,
            name: "$500.00",
            option: 500,
            value: 29.95,
            miscChargeName: "LDW",
            vehicleTypeName: "All",
            locationName: "All",
          },
          {
            miscChargeOptionId: 10528,
            name: "Collision Damage Waiver @ $15.00 Per Day",
            option: 200,
            value: 15,
            miscChargeName: "LDW",
            vehicleTypeName: "All",
            locationName: "All",
          },
          {
            miscChargeOptionId: 10529,
            name: "$50.00",
            option: 50,
            value: 0,
            miscChargeName: "LDW",
            vehicleTypeName: "All",
            locationName: "All",
          },
        ],
        selectedOption: {
          miscChargeOptionId: 10527,
          name: "$500.00",
          option: 500,
          value: 29.95,
          miscChargeName: "LDW",
          vehicleTypeName: "All",
          locationName: "All",
        },
        OptionId: 10527,
      },
    ],
    promoCode: "",
    VehicleId: "",
    firstName: "jashanpreet",
    lastName: "Singh",
    email: "jassa.developer@gmail.com",
    phoneNumber: "4372391838",
    dateOfBirth: "1997-07-02T03:58:41.866Z",
    driverLicNumber: "31421421312",
    driverLicExp: "2023-09-02T03:58:41.871Z",
    driverLicIssueDate: "2008-01-02T04:58:41.874Z",
    additionaldateOfBirth: "1999-01-02T04:58:41.877Z",
    additionaldriverLicNumber: "31421421312",
    additionaldriverLicExp: "2024-09-02T03:58:41.880Z",
    additionaldriverLicIssueDate: "2006-01-02T04:58:41.882Z",
    address: "3066 Bonaventure Drive",
    city: "Mississauga",
    country: 2,
    picCountry: 2,
    state: 13,
    picState: 13,
    zipCode: "L4T2J2",
    reservationNumber: null,
    creditCardNumber: "4532 4232 4232 3232",
    creditCardExpire: "12/24",
    cvv: "323",
    cardHolderName: "Jas",
    promoId: null,
    miscCharges: [
      {
        id: 23169,
        name: "Colorado Road Safety Fee",
        description: "Colorado Road Safety Fee",
        calculationType: "Perday",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 2,
        total: 2,
        locationMiscChargeId: 32674,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23170,
        name: "Preparation Fee",
        description: "Preparation Fee",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 60,
        total: 60,
        locationMiscChargeId: 32677,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23171,
        name: "Ultimate Winter Package",
        description: "Ultimate Winter Package",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 375,
        total: 375,
        locationMiscChargeId: 32683,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23159,
        name: "Inflatable Stand Up Paddleboard ",
        description: "*Capped at $150",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: true,
        isTaxable: true,
        value: 150,
        total: 150,
        locationMiscChargeId: 32671,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 1,
        name: "LDW",
        description: "Loss damage waiver",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: true,
        isTaxable: true,
        value: 0,
        total: 29.95,
        locationMiscChargeId: 32662,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: 10527,
      },
    ],
  },
  availableVehicles: [
    {
      VehicleTypeId: 33629,
      VehicleTypeName: "Smalls Campervan",
      FullValue: 0,
      Sample: "Camper Van",
      Seats: "6",
      Doors: "4",
      Baggages: 3,
      Transmission: "Auto",
      Deposit: 1000,
      Path: "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls Campervan_33629.jpg",
      HtmlContent: "",
      Price: "0",
      SIPP: null,
      Images: [
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls Campervan_33629.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls-1_33629.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls-2_33629.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls-3_33629.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Smalls-4_33629.jpg",
      ],
      Total: 3,
      TotalBooked: 1,
    },
    {
      VehicleTypeId: 33630,
      VehicleTypeName: "Biggie Campervan",
      FullValue: 0,
      Sample: "sample",
      Seats: "3",
      Doors: "4",
      Baggages: 4,
      Transmission: "Auto",
      Deposit: 2000,
      Path: "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Biggie Campervan_33630.jpg",
      HtmlContent: "",
      Price: "0",
      SIPP: null,
      Images: [
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Biggie Campervan_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-1_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-2_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-3_33630.jpg",
        "http://67.43.12.211/NavotarV2/Images/TypeImages/513/Bigvan-4_33630.jpg",
      ],
      Total: 3,
      TotalBooked: 2,
    },
  ],
  terms: [
    {
      id: 2081,
      clientId: 0,
      term: "Term01",
      description:
        "LOSS DAMAGE WAVER (LOW) DOES NOT APPLY TO DAMAGE CAUSED BY OFF THE ROAD USE, DAMAGE TO THE INTERIOR AND DAMAGE TO TRUCKS CAUSED BY COLLISION WITH STATIONARY OR OVERHEAD OBJECTS",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
    {
      id: 2082,
      clientId: 0,
      term: "Term02",
      description:
        "RENTER IS LIABLE FOR ALL DAMAGE AND COSTS TO VEHICLE CAUSED BY A DRIVER NOT NAMED IN THIS AGREEMENT, EVEN IF RENTER HAS PURCHASED LDW.",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
    {
      id: 2083,
      clientId: 0,
      term: "Term03",
      description:
        "RENTAL AGREE TO PAY ALL THE PARKING TICKETS, TRAFFIC VIOLATION AND TOLL ROAD CHARGERS INCURRED AND BE LIABLE FOR A SERVICE CHARGE OF $50.00 FOR EACH TICKET AND/OR VIOLATION PLUS ALL PARKING AND TRAFFIC FINES AND TOLL ROAD CHARGES.",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
    {
      id: 2084,
      clientId: 0,
      term: "Term04",
      description: "NO PARKING VIOLATION INCURRED DURING RENTAL",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
    {
      id: 2085,
      clientId: 0,
      term: "Term05",
      description: "RETURN DATE CONFIRM.",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
    {
      id: 2086,
      clientId: 0,
      term: "Term06",
      description:
        "IF THIS RENTAL IS TO BE CHARGED ON A CREDIT CARD: THE ISSUER OF THE CARD IDENTIFIED ON THIS ITEM IS AUTHORIZED TO PAY THE AMOUNT SHOWN AS TOTAL UPON PROPER PRESENTATION. I PROMISE TO PAY SUCH TOTAL WITH ANY OTHER CHARGES DUE THEREON SUBJECT TO AND IN ACCORDANCE WITH THE AGREEMENT GOVERNING THE USE OF SUCH CARD.",
      type: 0,
      createdDate: "0001-01-01T00:00:00",
      updatedDate: "2020-01-22T07:52:11.137",
      version: 1,
      oldReferencedId: 0,
      isDeleted: false,
    },
  ],
  imports: {
    flow: [null, null, null, null, null, null],
    dynamicStyle: {
      DformWrapper: "default_DformWrapper__2zoKd",
      DbookingFormContainer: "default_DbookingFormContainer__FZeaG",
      Dtitle: "default_Dtitle__31JSv",
      Dmt4: "default_Dmt4__19CZ5",
      DformErr: "default_DformErr__2FZ4M",
      DlightRoundedPill: "default_DlightRoundedPill__1G7_5",
      DTimePicker: "default_DTimePicker__3yQUx",
      DNavbar: "default_DNavbar__MNuSx",
      DNavPrice: "default_DNavPrice__29C-Y",
      DStepperDetails: "default_DStepperDetails__3mZ1q",
      DStepperVehicle: "default_DStepperVehicle__2d8Aw",
      DStepperMisc: "default_DStepperMisc__k90Fx",
      DMiscAcourdinWrapper: "default_DMiscAcourdinWrapper__bDaV0",
      DMiscAcourdin: "default_DMiscAcourdin__2MwOx",
      addMiscBtn: "default_addMiscBtn__2iLXY",
    },
  },
  auth: {
    token: "",
  },
  summery: {
    baseRate: 189,
    totalDays: 1,
    miscCharges: [
      {
        id: 23169,
        name: "Colorado Road Safety Fee",
        description: "Colorado Road Safety Fee",
        calculationType: "Perday",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 2,
        total: 2,
        locationMiscChargeId: 32674,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23170,
        name: "Preparation Fee",
        description: "Preparation Fee",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 60,
        total: 60,
        locationMiscChargeId: 32677,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23171,
        name: "Ultimate Winter Package",
        description: "Ultimate Winter Package",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: false,
        isTaxable: true,
        value: 375,
        total: 375,
        locationMiscChargeId: 32683,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 23159,
        name: "Inflatable Stand Up Paddleboard ",
        description: "*Capped at $150",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: true,
        isTaxable: true,
        value: 150,
        total: 150,
        locationMiscChargeId: 32671,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: null,
      },
      {
        id: 1,
        name: "LDW",
        description: "Loss damage waiver",
        calculationType: "Fixed",
        isQuantity: false,
        isOptional: true,
        isTaxable: true,
        value: 0,
        total: 29.95,
        locationMiscChargeId: 32662,
        startDate: null,
        endDate: null,
        quantity: null,
        units: null,
        optionId: 10527,
      },
    ],
    totalMiscChargesTaxable: 616.95,
    totalMiscChargesNonTaxable: 0,
    subTotal: 189,
    totalTax: 0,
    total: 805.95,
    promotionDiscount: 0,
    rateSummaryItems: [
      {
        type: "Daily",
        rate: 189,
        units: 1,
        startDate: "2020-12-02T09:00:00",
        endDate: "2020-12-03T17:00:00",
        kMorMileageAllowed: 0,
      },
    ],
    avgPerDayRate: 189,
    taxes: [],
    promotions: [],
    maxTotalDays: 0,
    freeMiles: 0,
    extraMilesCharge: 0,
  },
  config: {
    navbar: {
      steppers: [
        {
          title: "Select Vehicle",
          type: "Selected Vehicle",
          icon: "car",
        },
        {
          title: "Vehicle Details",
          type: "Vehicle Details",
          icon: "detail",
        },
        {
          title: "Extras",
          type: "Add Ons",
          icon: "plus-square",
        },
        {
          title: "Insurance",
          type: "Insurance",
          icon: "info",
        },
        {
          title: "Info",
          type: "User info",
          icon: "user-info",
        },
        {
          title: "Checkout",
          type: "Checkout",
          icon: "credit-card",
        },
      ],
    },
    vehicleDetails: [],
  },
  miscCharges: [
    {
      Id: 1,
      Name: "LDW",
      Total: 0,
      Description: "Loss damage waiver",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: true,
      IsTaxable: true,
      MiscChargeType: "Insurance",
      Options: [
        {
          miscChargeOptionId: 10526,
          name: "Assume Liability @ $0.00 Per Day",
          option: 100,
          value: 0,
          miscChargeName: "LDW",
          vehicleTypeName: "All",
          locationName: "All",
        },
        {
          miscChargeOptionId: 10527,
          name: "$500.00",
          option: 500,
          value: 29.95,
          miscChargeName: "LDW",
          vehicleTypeName: "All",
          locationName: "All",
        },
        {
          miscChargeOptionId: 10528,
          name: "Collision Damage Waiver @ $15.00 Per Day",
          option: 200,
          value: 15,
          miscChargeName: "LDW",
          vehicleTypeName: "All",
          locationName: "All",
        },
        {
          miscChargeOptionId: 10529,
          name: "$50.00",
          option: 50,
          value: 0,
          miscChargeName: "LDW",
          vehicleTypeName: "All",
          locationName: "All",
        },
      ],
    },
    {
      Id: 23162,
      Name: "Bring a Dog!",
      Total: 100,
      Description: "Bring a Dog!",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: true,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23159,
      Name: "Inflatable Stand Up Paddleboard ",
      Total: 150,
      Description: "*Capped at $150",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: true,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23169,
      Name: "Colorado Road Safety Fee",
      Total: 2,
      Description: "Colorado Road Safety Fee",
      CalculationType: "Perday",
      IsQuantity: false,
      IsOptional: false,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23170,
      Name: "Preparation Fee",
      Total: 60,
      Description: "Preparation Fee",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: false,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23164,
      Name: "Hammock",
      Total: 30,
      Description: "Hammock",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: true,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23171,
      Name: "Ultimate Winter Package",
      Total: 375,
      Description: "Ultimate Winter Package",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: false,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
    {
      Id: 23160,
      Name: "2 Person Tent",
      Total: 30,
      Description: "2 Person Tent",
      CalculationType: "Fixed",
      IsQuantity: false,
      IsOptional: true,
      IsTaxable: true,
      MiscChargeType: "Fuel",
      Options: [],
    },
  ],
  taxes: [],
};

export default data;
