import ActionType from "../actions/ActionsType";
import Loading from "../../components/Static/Loading/Loading";
import CompactNavbar from "../../components/NavigationDetails/NavigationDetails";
import DocsState from "../../Documentation/Redux/initState";

const init = {
  UISettings: {
    modals: {
      editDetails: false,
    },

    bannerColor: "red",
    buttonColor: "red",
    secondaryColor: "black",
    primaryColor: "red",

    currency: "USD",
    language: "en",
    defaultCountryIdx: 1,
    defaultStateIdx: 1,

    flow: "",
    homeView: "",
    theme: "",
    bookingSuccessView: "Default/BookingSuccess",
    footer: "Default",

    isAirLineInfoAvailable: false,
    isReservation: false,
    isVehicleCount: false,
    isAgeOnHome: 1,
    billingAddressInfo: "False",
    isDriversInfoOptional: "False",
    additionalDriver: false,
    navPrice: true,

    parent: "",
    maxCheckinTime: "09:30 AM",
    timeSelection: true,
    dateFormat: "dd-mm-yyyy",
    defaultCheckoutTime: "09:00 AM",
    defaultCheckinTime: "09:00 AM",
    driverLicense: "1",
    paymentInfo: "0",
    isPaymentInfoMandatory: "True",
    timeGap: 15,
    showClock: "Y",
    navBar: "CompactNavbar",
    showTermsAfter: 2,
    showSignInAfter: -1,
    minimumDriverAge: 18,
    prePaymentMessage: "",
    distanceUnit: "km",
    customerInfoScreenMessage: "",
    addOnsScreenMessage: "",
    noteFields: "",
    alwaysSamePickAndDrop: false,
    hideDistanceAllowed: false,

    logo: "",
    config: {},
    enableEditFormDetails: true,
    isAdvanceFromVehicleType: false,
    showPickupDropoffAddress: {},
    showInsuranceFields: false,
    hideOrDisableTimePicker: "",
    minimumBookingDays: "",
    earliestPickup: "",
    showVehiclesLeft: false,
    showCheckAvailability: false,
    showDescriptions: false,
    showDOB: true,
    showLogoOnHome: false,
    homeScreenMessage: "",
    hideVehicleFeature: false,
    hideLicenseIssueDate: false,
    showPromoCode: true,
  },
  trackingSettings: {},
  locations: [],
  storeHours: [],
  price: [],
  form: {
    CorpDiscoundNumber: "",
    location: "",
    differentDropOff: false,
    pickUpDate: new Date(),
    returnDate: new Date(),
    pickUpTime: new Date(),
    returnTime: new Date(),
    age: "",
    differentDropOffLocation: "",
    selectedVehicle: { VehicleTypeId: null },
    selectedMisc: [],
    selectedInventory: [],
    promoCode: "",
    VehicleId: "",

    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",

    dateOfBirth: "",
    driverLicNumber: "",
    driverLicExp: "",
    driverLicIssueDate: "",

    additionalDriversFName: "",
    additionalDriversLName: "",
    additionalDriversNumber: "",
    additionalDriversEmail: "",
    additionalDriverDateOfBirth: "",
    additionalDriverLicNumber: "",
    additionalDriverLicExp: "",
    additionalDriverLicIssueDate: "",

    address: "",
    city: "",
    country: "",
    picCountry: "",
    state: "",
    picState: "",
    zipCode: "",
    reservationNumber: null,
    creditCardNumber: "",
    creditCardExpire: "",
    cvv: "",
    cardHolderName: "",
    promoId: null,
    insuranceCompanyName: "",
  },

  availableVehicles: [],
  vehiclesLoaded: false,
  availableVehicleItems: [],

  terms: [],

  imports: {
    homePage: Loading,
    flow: [],
    dynamicStyle: {},
    bookingSuccessComponent: Loading,
    navbar: CompactNavbar,
    footer: Loading,
  },
  auth: {
    token: null,
  },
  summery: {
    baseRate: 0,
    totalDays: 0,
    totalMiscChargesTaxable: 0,
    totalMiscChargesNonTaxable: 0,
    subTotal: 0,
    totalTax: 0,
    total: 0,
    promotionDiscount: 0,
    rateSummaryItems: [],
    avgPerDayRate: 0,
    miscCharges: [],
    inventoryCharges: [],
    taxes: [],
    fees: [],
  },
  config: {
    navbar: {
      steppers: [],
      custom: false,
    },
    vehicleDetails: {
      summery: "",
      included: "",
    },
  },
  miscCharges: [],
  taxes: [],
  promotionDiscount: {
    RateTotalAmount: "",
    EstimatedTotalAmount: "",
    RateTotalAmountWithPromo: "",
    EstimatedTotalAmountWithPromo:"",
    PromotionDescription: "",
    Value: "",
    Error:"",
    Code:"",
    totalTaxes: "",

  }
};

export default function mainReducer(state = init, action) {
  switch (action.type) {
    case ActionType.SET_STORE_HOURS: {
      return {
        ...state,
        storeHours: action.data.storeHours,
      };
    }
    case ActionType.CHANGE_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.data.key]: action.data.value,
        },
      };
    }

    case ActionType.CLEAR_VEHICLE_TYPES: {
      return {
        ...state,
        availableVehicles: [],
      };
    }

    case ActionType.ADD_MISC: {
      let newMisc = [...state.form.selectedMisc];
      newMisc.push(action.data.misc);

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc,
        },
      };
    }
    case ActionType.ADD_INVENTORY: {
      let newInventory = [...state.form.selectedInventory];
      newInventory.push(action.data.inventory);

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory,
        },
      };
    }
    case ActionType.UPDATE_MISC: {
      let miscId = action.data.misc.id;
      let newMisc = [...state.form.selectedMisc];

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc.map((m) =>
            m.id === miscId
              ? {
                  ...m,
                  Quantity: action.data.quantity,
                  StartDate: action.data.startDate,
                  EndDate: action.data.endDate,
                }
              : m
          ),
        },
      };
    }
    case ActionType.UPDATE_INVENTORY: {
      let invId = action.data.inv.id;
      let newInventory = [...state.form.selectedInventory];

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory.map((m) =>
            m.id === invId
              ? {
                  ...m,
                  Quantity: action.data.quantity,
                  StartDate: action.data.startDate,
                  EndDate: action.data.endDate,
                }
              : m
          ),
        },
      };
    }
    case ActionType.REMOVE_MISC: {
      let newMisc = [...state.form.selectedMisc];
      const { miscId } = action.data;

      newMisc = newMisc.filter((item) => item.id !== miscId);

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc,
        },
      };
    }

    case ActionType.REMOVE_INVENTORY: {
      let newInventory = [...state.form.selectedInventory];
      const { inventoryId } = action.data;

      newInventory = newInventory.filter((item) => item.Id !== inventoryId);

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory,
        },
      };
    }

    case ActionType.RESET_MISC: {
      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: [],
        },
      };
    }

    case ActionType.SET_PRICE: {
      let price = [...state.price];
      price[action.data.position || state.UISettings.currentActiveFlow] =
        action.data.price;
      return {
        ...state,
        price: price,
      };
    }

    case ActionType.CLEAR_PRICE: {
      let price = [...state.price];
      price = price.map(() => 0);

      return {
        ...state,
        price: price,
      };
    }

    case ActionType.ADD_PRICE: {
      let price = [...state.price];
      price[state.UISettings.currentActiveFlow] += action.data.price;
      return {
        ...state,
        price: price,
      };
    }

    case ActionType.IMPORT_NAVBAR: {
      return {
        ...state,
        imports: {
          ...state.imports,
          navbar: action.data.component,
        },
      };
    }

    case ActionType.TOGGLE_MODAL: {
      return {
        ...state,
        UISettings: {
          ...state.UISettings,
          modals: {
            ...state.UISettings.modals,
            [action.data.key]: action.data.value,
          },
        },
      };
    }

    case ActionType.IMPORT_HOME_COMPONENT: {
      return {
        ...state,
        imports: {
          ...state.imports,
          homePage: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_BOOKING_SUCCESS_COMPONENT: {
      return {
        ...state,
        imports: {
          ...state.imports,
          bookingSuccessComponent: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_FOOTER: {
      return {
        ...state,
        imports: {
          ...state.imports,
          footer: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_FLOW_COMPONENT: {
      let index = state.UISettings.flow
        .split("-")
        .indexOf(action.data.componentName);
      let bb = [...state.imports.flow];

      bb[index] = action.data.component;

      return {
        ...state,
        imports: {
          ...state.imports,
          flow: [...bb],
        },
      };
    }

    case ActionType.IMPORT_STYLES: {
      return {
        ...state,
        imports: {
          ...state.imports,
          dynamicStyle: action.data.component,
        },
      };
    }

    case ActionType.REFRESH_TOKEN: {
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.data.token,
        },
      };
    }

    case ActionType.SET_DOCS: {
      return {
        ...DocsState,
      };
    }

    case ActionType.FETCH_CLIENT: {
      let price = [];
      let flowArr = action.data.Flow.split("-").map((_) => {
        price.push(0);
        return null;
      });

      return {
        ...state,
        price: [...price],
        locations: action.data.locations,
        ccMerchantInfo: action.data.ccMerchantInfo,
        storeHours: action.data.storeHours || [],
        storeHolidays: action.data.storeHolidays || [],
        UISettings: {
          ...state.UISettings,
          bannerColor: action.data.BannerColor || "red",
          buttonColor: action.data.ButtonColor || "red",
          clientId: action.data.ClientId,
          theme: action.data.Theme || "default",
          homeView: action.data.HomeView || "Default/IndexRentCar",
          bookingSuccessView:
            action.data.BookingSuccessView || "Default/BookingSuccess",
          primaryColor: action.data.PredominantColor || "red",
          secondaryColor: action.data.SecondaryColor || "red",
          dateFormat: action.data.DateFormat || "dd/MM/yyyy",
          language: action.data.Language || "en",
          flow: action.data.Flow || "Flow1-Flow2-Flow3",
          defaultCheckoutTime: action.data.DefaultCheckoutTime || "06:00 PM",
          defaultCheckinTime: action.data.DefaultCheckinTime || "09:00 AM",
          currency: action.data.Currency || "Currency Not defined",
          defaultCountryIndex: action.data.DefaultCountryIdx || 1,
          defaultStateIndex: action.data.DefaultStateIdx || 1,
          isAirLineInfoAvailable: false,
          isReservation: false,
          isVehicleCount: false,
          maxCheckinTime: action.data.MaxCheckinTime,
          parent: action.data.Parent || "",
          currentActiveFlow: 0,
          showStepper: true,
          minimumDriverAge: parseInt(action.data.MinimumDriverAge) || 18,
          prePaymentMessage: action.data.PrePaymentMessage || "",
          customerInfoScreenMessage:
            action.data.CustomerInfoScreenMessage || "",
          addOnsScreenMessage: action.data.AddOnsScreenMessage || "",
          noteFields: action.data.NoteFields
            ? JSON.parse(action.data.NoteFields)
            : null,
          vehicleSortBy: action.data.VehicleSortBy || null,
          vehicleSortDirection: action.data.VehicleSortDirection || null,
          distanceUnit: action.data.DistanceUnit || "km",
          footer: action.data.Footer || "Default",
          additionalDriver:
            action.data.ShowAdditionalDriverFields === "True" || false,
          alwaysSamePickAndDrop:
            action.data.AlwaysSamePickAndDrop === "True" || false,
          hideDistanceAllowed:
            action.data.ShowDistanceAllowed === "False" || false,
          timeSelection: action.data.TimeSelection || false,
          isAgeOnHome: action.data.IsAgeOnHome || "False",
          driverLicense: action.data.ShowLicenseFields || "False",
          paymentInfo: action.data.ShowPaymentFields || "False",
          isPaymentInfoMandatory: action.data.IsPaymentInfoMandatory || "True",
          billingAddressInfo: action.data.ShowBillingAddressFields || "False",
          showClock: action.data.ShowClock || "Y",
          isDriversInfoOptional: action.data.isDriversInfoOptional || "False",
          showTermsAfter: parseInt(action.data.ShowTermsAfter) || 2,
          showSignInAfter: parseInt(action.data.ShowSignInAfter) || -1,
          timeGap: parseInt(action.data.TimeGap) || 15,
          logo: action.data.Logo || "",
          navBar: action.data.NavBar || "Default/DefaultFlowNavbar",
          vehicleTypes: action.data.vehicleTypes || [],
          isAdvanceFromVehicleType:
            action.data.isAdvanceFromVehicleType || false,
          showPickupDropoffAddress: action.data.ShowPickupDropoffAddress || {},
          showInsuranceFields: action.data.ShowInsuranceFields || false,
          hideOrDisableTimePicker: action.data.HideOrDisableTimePicker || "",
          minimumBookingDays: action.data.MinimumBookingDays || "",
          earliestPickup: action.data.EarliestPickup || 0,
          showVehiclesLeft: action.data.ShowVehiclesLeft === "True" || false,
          showCheckAvailability:
            action.data.ShowCheckAvailability === "True" || false,
          showDescriptions:
            action.data.BluebirdShowDescriptions === "True" || false,
          showDOB: action.data.ShowBirthday === "True" || false,
          showLogoOnHome: action.data.ShowLogoOnHome === "True" || false,
          homeScreenMessage: action.data.HomeScreenMessage || "",
          hideVehicleFeature:
            action.data.HideVehicleFeature === "True" || false,
          hideLicenseIssueDate:
            action.data.HideLicenseIssueDate === "True" || false,
          showPromoCode: action.data.ShowPromoCode === "True",
          paymentPercentage:
            parseInt(action.data.BlueBirdPaymentPercentage) || 100,
          locationNameConfig: action.data.BluebirdLocationName || "All",
        },
        config:
          {
            ...action.data.Config,
            vehicleDetails: { ...(action.data.Config.vehicleDetails || {}) },
          } || {},

        imports: {
          ...state.imports,
          flow: flowArr,
        },
        form: {
          ...state.form,
          country: parseInt(action.data.DefaultCountryIdx) || null,
          picCountry: parseInt(action.data.DefaultCountryIdx) || null,
          state: parseInt(action.data.DefaultStateIdx) || null,
          picState: parseInt(action.data.DefaultStateIdx) || null,
        },
        paymentSettings: {
          transactionType: action.data.TransactionType,
          advancePaymentAmount: action.data.AdvancePaymentAmount,
        },
        trackingSettings: {
          googleTagContainerId: action.data.GoogleTagContainerId,
        },
      };
    }

    case ActionType.SET_VEHICLES: {
      return {
        ...state,
        availableVehicles: action.data.vehicles.vehicleTypes,
        vehiclesLoaded: true,

        form: {
          ...state.form,
          selectedMisc: [],
          companyName: action.data.vehicles.companyName,
          //    selectedVehicle: { VehicleTypeId: null }
        },
      };
    }

    case ActionType.CLEAR_VEHICLE_ITEMS: {
      return {
        ...state,
        availableVehicleItems: [],
      };
    }

    case ActionType.SET_VEHICLE_ITEMS: {
      return {
        ...state,
        availableVehicleItems: action.data.vehicles,
      };
    }

    case ActionType.SET_MISC: {
      return {
        ...state,
        miscCharges: action.data.miscCharges || [],
      };
    }

    case ActionType.SET_INVENTORY: {
      return {
        ...state,
        inventoryCharges: action.data.inventoryCharges || [],
      };
    }

    case ActionType.CHANGE_UISETTINGS: {
      return {
        ...state,
        UISettings: {
          ...state.UISettings,
          [action.data.key]: action.data.value,
        },
      };
    }

    case ActionType.SET_TAXES: {
      return {
        ...state,
        taxes: action.data[0]?.taxes || [],
        fees: action.data[0]?.fees || [],
      };
    }

    case ActionType.SET_SUMMERY: {
      return {
        ...state,
        summery: action.data.summery,
      };
    }

    case ActionType.SET_TERMS: {
      return {
        ...state,
        terms: action.data.terms,
      };
    }

    
        case ActionType.SET_PROMO_CODE:
          return {
            ...state,
            form: {
              ...state.form,
              promoCode: action.payload
            }
          };
        // other cases
        case ActionType.SET_PROMOTION_DISCOUNT: {
          return {
            ...state,
            promotionDiscount: action.payload
          };
        }
       
    // ************** Documentation ****************

    case ActionType.SET_STEPPER: {
      return {
        ...state,
        config: {
          ...state.config,
          navbar: { ...state.config.navbar, steppers: action.data.steppers },
        },
      };
    }

    default: {
      return state;
    }
  }
}
