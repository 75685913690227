
import ActionType from './ActionsType';

const setSummery = summery => {
    return dispatch => {
        try {

            dispatch({ type: ActionType.SET_SUMMERY, data: { summery } });

        } catch (err) {
            console.log(err);
            alert('Error occured while loading the summery.')
        }
    }
}

 const setPromotionDiscount = (promotionDiscount) => {
    return (dispatch) =>dispatch( {
      type: ActionType.SET_PROMOTION_DISCOUNT,
      payload: promotionDiscount
    });
  };

export { setSummery ,setPromotionDiscount};
